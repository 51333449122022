<template>
  <div class="search-results">
    <!-- Search results are rendered here -->
  </div>
  <aside>
    <button aria-label="More About Us" class="info button flex flex--ac flex--jc" id="about-us-toggle" title="About Us" @click="() => aboutUsActive = true">
      <svg aria-label="i" width="4px" height="20px" viewBox="0 0 2 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="2" height="2" fill="white"/>
        <rect y="4" width="2" height="6" fill="white"/>
      </svg>
    </button>
    <button aria-label="Scroll to top" title="Scroll to top" id="scroll-top-toggle" class="scrollTop button ">
      <svg aria-label="arrow icon" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 50 50" version="1.1" width="50px" height="50px">
        <g id="surface1">
          <!-- eslint-disable-next-line max-len -->
          <path fill="#D81E5B" d="M 25 2 C 12.316406 2 2 12.316406 2 25 C 2 37.683594 12.316406 48 25 48 C 37.683594 48 48 37.683594 48 25 C 48 12.316406 37.683594 2 25 2 Z M 33.707031 21.707031 C 33.511719 21.902344 33.257813 22 33 22 C 32.742188 22 32.488281 21.902344 32.292969 21.707031 L 26 15.414063 L 26 37 C 26 37.554688 25.550781 38 25 38 C 24.449219 38 24 37.554688 24 37 L 24 15.414063 L 17.707031 21.707031 C 17.316406 22.097656 16.683594 22.097656 16.292969 21.707031 C 15.902344 21.316406 15.902344 20.683594 16.292969 20.292969 L 24.292969 12.292969 C 24.386719 12.199219 24.496094 12.128906 24.617188 12.078125 C 24.863281 11.976563 25.136719 11.976563 25.382813 12.078125 C 25.503906 12.128906 25.613281 12.199219 25.707031 12.292969 L 33.707031 20.292969 C 34.097656 20.683594 34.097656 21.316406 33.707031 21.707031 Z "/>
        </g>
      </svg>
    </button>
  </aside>
  <section aria-label="About Fair Shopping" class="info-box" id="about-us-box" v-if="aboutUsActive">
    <div class="backdrop" role="presentation" @click="() => aboutUsActive = false"></div>
    <div class="inner">
      <button class="close" @click="() => aboutUsActive = false">
        <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 26 26" version="1.1" width="26px" height="26px">
          <g id="surface1">
            <!-- eslint-disable-next-line max-len -->
            <path d="M 6.65625 4 C 6.367188 4 6.105469 4.113281 5.90625 4.3125 L 4.3125 5.90625 C 3.914063 6.304688 3.914063 7 4.3125 7.5 L 9.8125 13 L 4.3125 18.5 C 3.914063 19 3.914063 19.695313 4.3125 20.09375 L 5.90625 21.6875 C 6.40625 22.085938 7.101563 22.085938 7.5 21.6875 L 13 16.1875 L 18.5 21.6875 C 19 22.085938 19.695313 22.085938 20.09375 21.6875 L 21.6875 20.09375 C 22.085938 19.59375 22.085938 18.898438 21.6875 18.5 L 16.1875 13 L 21.6875 7.5 C 22.085938 7 22.085938 6.304688 21.6875 5.90625 L 20.09375 4.3125 C 19.59375 3.914063 18.898438 3.914063 18.5 4.3125 L 13 9.8125 L 7.5 4.3125 C 7.25 4.113281 6.945313 4 6.65625 4 Z "/>
          </g>
        </svg>
      </button>
      <h1>Fair Shopping</h1>
      <p>
        <a href="/">Fairshopping.online</a> is a project created by the <a href="https://zoovu.com">Zoovu</a> team.
        The goal of Fair Shopping is to collect fair and ethical products from different manufacturers and put the powerful search engine of Zoovu behind it.
        This way you can find products you can buy without having to worry about causing any harm.
      </p>
      <a href="/privacy-policy">Our Privacy Policy</a>
      <h2>Stay in touch</h2>
      <div class="flex flex--ac flex--jsa w-100">
        <a href="https://x.com/zoovu" target="_blank" title="Twitter">
          <img src="./../assets/twitter.svg" width="70" height="70" aria-label="Twitter" />
        </a>
        <a href="https://www.facebook.com/zoovu.company" target="_blank" title="Facebook">
          <img src="./../assets/facebook.svg" width="70" height="70" aria-label="Facebook" />
        </a>
        <a href="https://www.linkedin.com/company/zoovu/" target="_blank" title="LinkedIn">
          <img src="./../assets/linkedin.svg" width="70" height="70" aria-label="LinkedIn" />
        </a>
        <a href="https://www.youtube.com/channel/UCGkH-TW9uk0xvkoQmvNN8oA" target="_blank" title="YouTube">
          <img src="./../assets/youtube.svg" width="70" height="70" aria-label="YouTube" />
        </a>
        <a href="https://api.contenthub.cloud/projects/5c1fa4d2cc25893730b3ede4/documents.atom" target="_blank" title="RSS">
          <img src="./../assets/rss.svg" width="70" height="70" aria-label="RSS" />
        </a>
        <a href="https://www.amazon.com/Skyboost-Fair-Shopping/dp/B07JL9LM7T" target="_blank" title="Amazon Alexa">
          <img src="./../assets/amazon-alexa.svg" width="70" height="70" aria-label="Amazon Alexa" />
        </a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
	name: 'Home',
	data() {
		return {
			aboutUsActive: false
		};
	},
	mounted() {
	},
	beforeRouteEnter(to, from, next) {
		next();
	}
};
</script>

<style>

</style>