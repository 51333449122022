<template>
  <header class="header">
    <h1 aria-label="Fair Shopping" class="h1"><a href="/"><img class="logo" src="./../assets/logo.svg" role="presentation" /></a></h1>
    <section role="search" class="search flex flex--js">
      <input class="search__input" type="search" placeholder="Search fair products" v-model="query">
      <nav role="navigation" aria-label="Explore Topics" class="topics">
        <ul role="menubar" class="flex flex--ac">
          <li role="menuitem" class="topic"><button class="button" @click="search('Bags')">Bags</button></li>
          <li role="menuitem" class="topic topic--p0"><button class="button" @click="search('Belts')">Belts</button></li>
          <li role="menuitem" class="topic"><button class="button" @click="search('Dresses')">Dresses</button></li>
          <li role="menuitem" class="topic topic--p2"><button class="button" @click="search('Sneakers')">Sneakers</button></li>
          <li role="menuitem" class="topic topic--p3"><button class="button" @click="search('Jewelry')">Jewelry</button></li>
          <li role="menuitem" class="topic"><button class="button" @click="search('T-Shirts')">T-Shirts</button></li>
          <li role="menuitem" class="topic"><button class="button" @click="search('Recycled')">Recycled</button></li>
          <li role="menuitem" class="topic topic--p4"><button class="button" @click="search('Vintage')">Vintage</button></li>
          <li role="menuitem" class="topic topic--p1"><button class="button" @click="search('Wallets')">Wallets</button></li>
        </ul>
      </nav>
    </section>
  </header>
</template>

<script>
export default {
	name: 'Toolbar',
	data() {
		return {
			query: ''
		};
	},
	methods: {
		search(query) {
			this.query = query;
			window.SS360.showResults(query);
		}
	}
};
</script>

<style lang="scss">
.header {
  z-index: 99999;
}

.branding {
  display: block;
  text-align: right;
  width: 100%;

  &__logo {
    max-height: 20px;
    padding: 0 1em;
  }
}
</style>