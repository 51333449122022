<template>
  <div class="breadcrumb row">
    <div style="margin-right: 75px" class="column _25"></div>
    <div class="column _35">
      <div class="row">
        <div v-for="(breadcrumb) in breadcrumbs" :key="breadcrumb.name">
          <a class="breadcrumb__text" :href="breadcrumb.link">{{breadcrumb.name}}</a>
          <span class="breadcrumb__arrow">➤</span>
        </div>
        <span class="breadcrumb__text">{{this.title}}</span>
      </div>
    </div>
    <div class="column _25"></div>
  </div>
  <div class="product row">
    <div class="column image-chooser _25">
      <div class="arrow-button arrow-button__up" :class="{'arrow-button__hide': altImageStart === 0}" @click="altImagesUp()"></div>
      <img
        v-for="(img, index) in altImages" :key="index"
        v-show="index >= altImageStart && index <= altImageEnd"
        :data-src="img"
        :src="img"
        class="alt-image loading"
        :class="{'alt-image__selected': index === selectedImageIndex}"
        @mouseover="selectImage(index)"
      />
      <div class="arrow-button arrow-button__down" :class="{'arrow-button__hide': altImageEnd >= (altImages.length - 1)}" @click="altImagesDown()"></div>
    </div>
    <div class="column _50">
      <div class="row">
        <div class="column" style="align-items: center;">
          <img class="image loading" :src="selectedImage"/>
          <div v-if="this.identifier">
            <!-- ZOOVU_ZOE -->
            <zoovu-zoe
              region="eu"
              api-key="ks_h6xcc37z96zTfWpMkfIhg-b8"
              locale="en-IE"
              :sku="this.identifier"
              :context="this.zoeContext"></zoovu-zoe>
            <!-- ZOOVU_ZOE -->
          </div>
        </div>

        <div class="column info">
          <div>
            <div class="title">{{ title }}</div>
            <div class="brand">{{ brand }}</div>
          </div>
          <div class="price">{{ this.currency ? this.currency :'$' }}{{ price }}</div>

          <div class="row rating-wrapper">
            <div class="rating">
              <span>{{ isRatingFilled(1) ? '&#9733;' : '&#9734;'}}</span>
              <span>{{ isRatingFilled(2) ? '&#9733;' : '&#9734;'}}</span>
              <span>{{ isRatingFilled(3) ? '&#9733;' : '&#9734;'}}</span>
              <span>{{ isRatingFilled(4) ? '&#9733;' : '&#9734;'}}</span>
              <span>{{ isRatingFilled(5) ? '&#9733;' : '&#9734;'}}</span>
            </div>
            <div class="review">| {{ reviews }} reviews</div>
          </div>
          <div>
            <div class="row" style="justify-content: space-between" v-for="(fact, index) in facts" :key="index">
              <span>{{fact.name}}:</span><span>{{fact.value}}</span>
            </div>
          </div>
          <div>
            <span>Size:</span>
            <div class="row row-wrapper">
              <span class="size">S</span>
              <span class="size">M</span>
              <span class="size">L</span>
              <span class="size">XL</span>
              <span class="size">XXL</span>
            </div>
          </div>
          <div class="row row-wrapper">
            <!-- eslint-disable-next-line max-len -->
            <svg class="truck" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" version="1.1" id="Layer_1" x="0px" y="0px" viewBox="0 0 29 16" style="enable-background:new 0 0 29 16;" xml:space="preserve">
              <g>
                <path class="st10" d="M18.9,8.4L18.9,8.4v3.4v0.1H19h0.4h0.1v-0.1c0.4-1.2,1.5-2.1,2.9-2.1c1.3,0,2.5,0.9,2.9,2.1v0.1h0.1h2h0.1   v-0.1V8.5V8.4H18.9L18.9,8.4z"></path>
                <path class="st10" d="M1.3,1.3v0.1v10.5V12h0.1h2.1h0.1v-0.1c0.4-1.3,1.6-2.2,3-2.2c1.3,0,2.5,0.9,2.9,2.1v0.1h0.1h7.9h0.1v-0.1v-4   V3V1.4L1.4,1.3H1.3z"></path>
                <polygon class="st10" points="26.8,7.2 26.6,7 23.4,3.6 18.9,3.6 18.8,3.6 18.8,3.7 18.8,7.1 26.6,7.2  "></polygon>
                <!-- eslint-disable-next-line max-len -->
                <path class="st11" d="M24.1,2.6c-0.1-0.1-0.3-0.2-0.4-0.2h-4.8h-0.1V2.3V0.7c0-0.3-0.3-0.6-0.6-0.6H0.7c-0.3,0-0.6,0.3-0.6,0.6v11.9   c0,0.3,0.3,0.6,0.6,0.6h2.7l0,0l0.1,0.1c0.2,1.5,1.5,2.6,3.1,2.6c1.5,0,2.9-1.2,3.1-2.7l0.1-0.1l0,0h6.9h2.6l0,0l0.1,0.1   c0.2,1.5,1.5,2.7,3.1,2.7s2.9-1.2,3.1-2.7l0.1-0.1l0,0h2.6c0.3,0,0.6-0.2,0.6-0.7V7.9c0-0.1,0-0.1-0.1-0.2   C28.2,7,24.1,2.6,24.1,2.6z M6.6,14.8c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C8.6,13.9,7.7,14.8,6.6,14.8z M17.6,3v4.8   v4v0.1h-0.1H9.6l0,0l-0.1-0.1c-0.4-1.2-1.6-2.1-2.9-2.1c-1.4,0-2.6,0.9-3,2.2L3.5,12l0,0H1.4H1.3v-0.1V1.4V1.3h0.1l16.2,0.1V3z    M18.8,3.7V3.6h0.1h4.5L26.6,7l0.2,0.2h-0.2l-7.8-0.1V3.7z M22.4,14.8c-1.1,0-2-0.9-2-2c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2   C24.4,13.9,23.5,14.8,22.4,14.8z M27.5,8.5v3.3v0.1h-0.1h-2l0,0l-0.1-0.1c-0.4-1.2-1.6-2.1-2.9-2.1c-1.4,0-2.5,0.9-2.9,2.1   l-0.1,0.1l0,0H19h-0.1v-0.1V8.5V8.4H19L27.5,8.5L27.5,8.5z"></path>
              </g>
            </svg>
            <span>Ships in 3-5 business days</span>
          </div>
          <a :href="link" target="_blank">
            <button class="button">
              ADD TO CART
            </button>
          </a>
          <div>
            <span>Verified for you</span>
            <ul class="zoe-attributes">
              <li v-for="(value, index) in values" :key="index">
                {{value}}
                <zoovu-zoe-attribute
                  region="eu"
                  api-key="ks_h6xcc37z96zTfWpMkfIhg-b8"
                  locale="en-IE"
                  :sku="this.identifier"
                  :context="this.zoeContext"
                  :attribute-name="value"
                  :attribute-value="value"
                />
              </li>
            </ul>
          </div>
          <div>
            <span class="heading">Product details</span>
            <p v-if="shortDescriptions.length === 0">No product details available</p>
            <ul v-else>
              <li style="padding-left: 10px" v-for="(description, index) in shortDescriptions" :key="index">{{description}}</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="column related-content _25"></div>
  </div>
  <div class="description row" style="justify-content: center">
    <div class="column _25"></div>
    <div class="column _35">
      <span class="heading">Description</span>
      <p v-for="(description, index) in descriptionParagraphs" :key="index">{{description}}</p>
    </div>
    <div class="column _25"></div>
  </div>
  <div class="row" style="justify-content: center" id="related_content">
  </div>
  <div style="display: none" id="identifier">{{ identifier }}</div>
</template>

<script>
import axios from 'axios';

const WHITELISTED_ATTRIBUTES = ['Brand', 'Country of Origin', 'Availability', 'Material', 'Color', 'Jewelry Material', 'Description-full', 'ShortDescription', 'Value', 'SKU']
	.map(attr => attr.toLowerCase().trim());

export default {
	name: 'ProductDetails',
	data() {
		return {
			title: undefined,
			images: undefined,
			link: undefined,
			description: 'undefined',
			shortDescriptions: [],
			values: [],
			altImages: [],
			initialImage: undefined,
			brand: undefined,
			price: undefined,
			currency: undefined,
			color: undefined,
			rating: 0,
			reviews: 0,
			altImageStart: 0,
			altImageEnd: 2,
			selectedImageIndex: 0,
			identifier: undefined,
			facts: [],
			categories: []
		};
	},
	computed: {
		productId() {
			return this.$route.query.id;
		},
		altImagesToShow() {
			return this.altImages.slice(this.altImageStart, this.altImageEnd);
		},
		selectedImage() {
			if (this.altImages.length > 0) {
				const url = this.altImages[this.selectedImageIndex];
				if (url)	return url;
			}

			return this.initialImage;
		},
		breadcrumbs() {
			let link = '/cat/';
			const breadcrumbs = [];
			this.categories.forEach(category => {
				const replaced = category === 'Fashion Accessories' ? 'accessories' : category.toLowerCase().replace(/\s/g, '-');
				link = `${link}${replaced}/`;
				breadcrumbs.push({
					name: category,
					link
				});
			});
			return breadcrumbs;
		},
		zoeContext() {
			return JSON.stringify({ isPlantLover: this.color === 'green' });
		},
		descriptionParagraphs() {
			return this.description ? this.description.split('<#>') : 'No description available';
		}
	},
	methods: {
		isRatingFilled(number) {
			return number <= this.rating;
		},
		altImagesUp() {
			if (this.altImageStart > 0 && this.altImageEnd > 2) {
				this.altImageStart--;
				this.altImageEnd--;
				if (this.selectedImageIndex >= 0) {
					this.selectedImageIndex--;
				}
			}
		},
		altImagesDown() {
			if (this.altImageStart < (this.altImages.length - 3) && this.altImageEnd < (this.altImages.length - 1)) {
				this.altImageStart++;
				this.altImageEnd++;
				if (this.selectedImageIndex < (this.altImages.length - 1)) {
					this.selectedImageIndex++;
				}
			}
		},
		selectImage(index) {
			this.selectedImageIndex = index;
		}
	},

	beforeMount() {
		const src = 'https://assets-staging-runner.zoovu.com/core-js/zoovu-tag-library/zoovu-tag-library-latest.min.js';

		if (!document.getElementById('ztl')) {
			const js = document.createElement('script');

			js.setAttribute('src', src);
			js.setAttribute('id', 'ztl');

			document.head.appendChild(js);
		}
	},
	async mounted() {
		// get random rating & reviews
		this.rating = Math.floor(Math.random() * 5) + 3;
		this.reviews = Math.floor(Math.random() * 300) + 1;

		axios.get(`https://api.search.zoovu.com/products/${this.productId}?projectId=20870&apiKey=6e78trvi5255lo21347z4ip766313ege`)
			.then(response => {
				if (response.data.status !== 'success') {
					// eslint-disable-next-line
					alert('Product not found!');
				}

				const product = response.data.data;

				if (product !== undefined) {
					this.identifier = product.identifier;
					this.title = product.name;
					this.link = product.link;
					this.selectedImageIndex = -1;
					this.initialImage = product.images[0].url;
					this.categories = product.categories[0].path;
					const altImages = [];

					product.facts.forEach(fact => {
						switch (fact.attribute) {
							case 'Description-full':
								this.description = fact.value;
								break;
							case 'ShortDescription':
								this.shortDescriptions.push(fact.value);
								break;
							case 'Values':
							case 'Value':
								this.values.push(fact.value);
								break;
							case 'Color':
								this.color = fact.value;
								break;
							case 'Price':
								this.price = fact.value;
								this.currency = fact.unit;
								break;
							case 'Brand':
								this.brand = fact.value;
								break;
							case 'Alternative Images':
							case 'Alternative images':
								altImages.push(fact.value);
								break;
							case 'Availability':
							case 'Title':
							case '_row-number':
								break;
							default:
								if (!fact.attribute.startsWith('sx') && WHITELISTED_ATTRIBUTES.indexOf(fact.attribute.toLowerCase()) !== -1) {
									this.facts.push({
										name: fact.attribute,
										value: fact.value
									});
								}
								break;
						}
					});

					altImages.forEach(image => {
						const parts = image.split('.jpg');
						this.altImages.push(`${parts[0]}_980x.jpg${parts[1]}`);
					});

					this.values.sort();
					this.facts.sort((a, b) => {
						if (a.name < b.name) {
							return -1;
						}
						if (a.name > b.name) {
							return 1;
						}
						return 0;
					});
				}

				if (window.initializeSs360) {
					window.initializeSs360();
				}
			});
	}
};
</script>

<style scoped lang="scss">
@import './../css/_variables.scss';

zoovu-zoe {
	--primary-color: #{$primary-color};

	position: relative;
	display: block;
	margin: 20px 3px;
	padding: 5px;
	border-radius: 5px;
	box-shadow: 0 0 5px lightgrey;
}

zoovu-zoe::part(user-message) {
	border-radius: 5px;
}

.breadcrumb {
	margin-bottom: 30px;

	.breadcrumb__text {
		font-size: 1.1em;
		margin-right: 5px;
	}

	.breadcrumb__arrow {
		margin-right: 5px;
		color: $primary-color;
	}
}

.product {
  color: #0a263d;

	.image {
		margin-top: 40px; //arrow-button height + image-chooser gap
		max-width: 100%;
		border-radius: 19px;
    box-shadow: -1px 12px 12px -12px rgba(0,0,0,0.16);
	}

	@media screen and (max-width: 800px) {
		.image {
			// max-height: 400px;
			// object-fit: contain;
			// display: block;
			display: block;
			max-width:400px;
			max-height:400px;
			width: auto;
			height: auto;
		}
	}

	.info {
		margin-left: 100px;
		gap: 20px;
		overflow: auto;
	}

	@media screen and (max-width: 800px) {
		.info {
			align-items: center;
			margin-left: 0;
			margin-top: 50px;
		}
	}

	.title {
		font-size: 27px;
    line-height: 32px;
    letter-spacing: .3px;
	}

	.brand {
    font-size: 15px;
    line-height: 25px;
    border-bottom: 1px solid #0A263D;
	}

	.price {
		font-family: Work Sans,sans-serif;
    font-weight: 400;
    font-size: 18px;
    letter-spacing: .25px;
    line-height: 1em;
    padding-left: 0;
    white-space: nowrap;
    margin-top: 20px;
    display: inline-block;
	}

	.rating-wrapper {
		border-bottom: 1px solid #dadee2;
	}

	.rating {
		color: orange;
		font-weight: 400;
    font-size: 30px;
	}

	.review {
		font-weight: 400;
    font-size: 20px;
		margin-left: 10px;
		display: flex;
		align-items: center;
	}

	.row-wrapper {
		gap: 10px;
	}

	.size {
		color: #707274;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 40px;
		width: 40px;
		border: 1px solid #dadee2;
		border-radius: 50%;
	}

	.size:hover {
		cursor: pointer;
		color: #0a263d;
		border-color: #0a263d;
	}

	.truck {
		height: 30px;
		width: 30px;

		.st10{fill:none;}
		.st11{fill:#0A263D;}
	}

	.button {
		color: $primary-color;
		width: 100%;
		height: 50px;
		font-weight: 800;
		letter-spacing: 2.04px;
	}

	.button:hover {
		color: white;
	}

	ul li::marker {
		content: '\27A4';
		color: $primary-color;
	}

	.image-chooser {
		align-items: end;
		margin-right: 75px;
		width: 150px;
		gap: 5px;
	}

	@media screen and (max-width: 800px) {
		.image-chooser {
			flex-direction: row;
			flex-wrap: wrap;
			margin-right: 0;
			justify-content: space-between;
		}
	}
	.arrow-button {
		width: 150px;
		height: 35px;
		user-select: none;
		text-align: center;
		font-size: 20px;
		color: $primary-color;

		&__up {
			border-top-left-radius: 19px;
			border-top-right-radius: 19px;
		}

		&__up:after {
			content: '\25B2';
		}

		&__down {
			border-bottom-left-radius: 19px;
			border-bottom-right-radius: 19px;
		}

		&__down:after {
			content: '\25BC';
		}

		&:hover {
			background-color: $primary-color;
			color: white;
			cursor: pointer;
		}

		&__hide {
			visibility: hidden;
		}
	}

	@media screen and (max-width: 800px) {
		.arrow-button {
			width: 35px;
			height: 120px;
			line-height: 120px;

			&__up {
				border-top-left-radius: 19px;
				border-top-right-radius: 0;
				border-bottom-left-radius: 19px;
			}

			&__up:after {
				content: '\140A';
			}

			&__down {
				border-bottom-left-radius: 0;
				border-bottom-right-radius: 19px;
				border-top-right-radius: 19px;
			}

			&__down:after {
				content: '\1405';
			}
		}
	}

	.alt-image {
		width: 150px;
		height: 150px;
		border-radius: 19px;
		cursor: pointer;
		object-fit: contain;

		&__invisble {
			display: none;
		}

		&__selected {
			border: 1px solid $primary-color;
		}
	}

	@media screen and (max-width: 800px) {
		.alt-image {
			width: 120px;
			height: 120px;
		}
	}

	.related-content {
		align-items: start;
	}

	.related-content section.ss360-related-content {
		margin-left: 75px;
		margin-top: 0;
	}
}

.description {
	padding-top: 20px;

	.heading {
		font-size: 20px;
    font-weight: 600;
	}

	ul li::marker {
		content: '\27A4';
		color: $primary-color;
	}
}

.values {
	padding-top: 20px;

	.heading {
		font-size: 20px;
    font-weight: 600;
	}

	ul li::marker {
		content: '\27A4';
		color: $primary-color;
	}
}

</style>